import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import { Link } from 'gatsby-plugin-intl'
import Language from "./Language"
import LogoImage from "../images/logo.png"

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div className="bg-primaryLight">
      <div className="container pt-6 pb-12 md:pt-12">
        <div className="flex justify-between items-center">
          <Link to="/">
            <img
              alt="Logo"
              src={LogoImage}
              className="transition duration-150 ease-in-out"
            />
          </Link>

          <div className="sm:block">
            <span>　　</span>
          </div>

          <button
            className="sm:hidden"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
          </button>

          <div className="flex justify-end items-center">
            <div className="hidden sm:block">
              {site.data.menu.map((link, key) => (
                <Link
                  key={`menu_desktop_link${key}`}
                  className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
                  activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
                  to={link.to}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <div className="hidden sm:block">
              {"　｜　"}
            </div>
            <div className="hidden sm:block">
              <Language isMenuOpen={isMenuOpen} />
            </div>
          </div>
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
    </div>
  )
}

export default Header
