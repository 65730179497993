import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "EN",
  ja: "JA",
}

const Language = ({ isMenuOpen }) => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <a
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              fontWeight: currentLocale === language ? `bold` : `100`,
              color: isMenuOpen ? `white` : `black`,
              margin: 6,
              marginTop: isMenuOpen ? 12 : 6,
              cursor: `pointer`,
            }}
          >
            {languageName[language]}
          </a>
        ))
      }
    </IntlContextConsumer>
  )
}

export default Language
